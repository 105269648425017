<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-label">
                <h5 class="wizard-title" style="color: black;">A. TAMPILAN</h5>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h5 class="wizard-title" style="color: black;">B. EMPATI</h5>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h5 class="wizard-title" style="color: black;">C. KETERANDALAN</h5>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h5 class="wizard-title" style="color: black;">D. DAYA TANGGAP</h5>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h5 class="wizard-title" style="color: black;">E. JAMINAN KEPASTIAN</h5>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h5 class="wizard-title" style="color: black;">F. TOTAL KINERJA</h5>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-label">
                <h5 class="wizard-title" style="color: black;">G. PERSEPSI</h5>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-10">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                    <h4 class="mb-10 font-weight-bold text-dark">A TANGIBLE/TAMPILAN</h4>
                    <div class="my-5">
                        <div class="card card-custom">
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%">No</th>
                                            <th style="width: 55%">DIMENSI KINERJA LAYANAN PTSP PEMDA </th>
                                            <th style="width: 40%">PENILAIAN (Masing-masing kolom, diisi dengan rentang nilai 1 sd 10)</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Kelengkapan sarana dan prasarana di PTSP</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan1-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k"  :value="datakepentingan.value" @click="_kepentinganClick" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba1_kepentingan}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan1-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba1_harapan}}</div>
                                                    </b-button-group>
                                               
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja1-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba1_kinerja}}</div>
                                                    </b-button-group>
                                                
                                                </div>

                                                <!-- {{selectedtampilan1}} -->
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2 </p></td>
                                            <td> <p style="font-size: 14px;">Kondisi fisik sarana dan prasarana di PTSP</p> </td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan2-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick2" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba2_kepentingan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan2-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick2" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba2_harapan}}</div>
                                                    </b-button-group>
                                                    
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja2-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick2" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba2_kinerja}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td> <p style="font-size: 14px;">3 </p></td>
                                            <td><p style="font-size: 14px;">Penampilan fisik SDM PTSP Pemda </p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan3-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick3" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba3_kepentingan}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan3-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick3" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba3_harapan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja3-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick3" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pba3_kinerja}}</div>
                                                    </b-button-group>
                                                  
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="status.status_sistem_pk === 'Buka'" type="button" @click="updatetangible" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan Form</button>
                                <!--end: Datatable-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">B. EMPATHY/EMPATI</h4>
                    <div class="my-5">
                        <div class="card card-custom">
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%">No</th>
                                            <th style="width: 55%">DIMENSI KINERJA LAYANAN PTSP PEMDA </th>
                                            <th style="width: 40%">PENILAIAN (Masing-masing kolom, diisi dengan rentang nilai 1 sd 10)</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Kemudahan menemukan kantor PTSP Pemda melalui papan petunjuk/peta internet (<em> Google Map </em>)</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan1b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick1b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb1_kepentingan}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan1b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick1b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb1_harapan}}</div>
                                                    </b-button-group>
                                                  
                                                </div>
                                                <div class="form-group" >
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja1b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick1b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb1_kinerja}}</div>
                                                    </b-button-group>
                                               
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Kemudahan berkomunikasi dengan PTSP Pemda (secara langsung/melalui media komunikasi)</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan2b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick2b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb2_kepentingan}}</div>
                                                    </b-button-group>
                                                  
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan2b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick2b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb2_harapan}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                                <div class="form-group" >
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja2b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick2b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb2_kinerja}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                                    
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Kejelasan prosedur/tata cara perizinan berusaha di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan3b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick3b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb3_kepentingan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan3b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick3b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb3_harapan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja3b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick3b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb3_kinerja}}</div>
                                                    </b-button-group>
                                              
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">4</p></td>
                                            <td><p style="font-size: 14px;">Kualitas pelayanan pengaduan di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan4b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick4b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb4_kepentingan}}</div>
                                                    </b-button-group>
                                                
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan4b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick4b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb4_harapan}}</div>
                                                    </b-button-group>
                                                    
                                                </div>
                                                <div class="form-group">
                                                    <p>Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja4b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick4b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb4_kinerja}}</div>
                                                    </b-button-group>
                                                  
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">5</p></td>
                                            <td><p style="font-size: 14px;">Ketersediaan terobosan pelayanan di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan5b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick5b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb5_kepentingan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan5b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick5b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb5_harapan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja5b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick5b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb5_kinerja}}</div>
                                                    </b-button-group>
                                                  
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">6</p></td>
                                            <td><p style="font-size: 14px;">Kualitas terobosan pelayanan di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan6b-container"  class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick6b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb6_kepentingan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan6b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick6b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb6_harapan}}</div>
                                                    </b-button-group>
                                                    
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja6b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick6b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb6_kinerja}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">7</p></td>
                                            <td><p style="font-size: 14px;">Kepedulian PTSP terhadap kepatuhan perusahaan (LKPM, Pemenuhan Komitmen, dan lain sebagainya)</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan7b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick7b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb7_kepentingan}}</div>
                                                    </b-button-group>
                                             
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan7b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick7b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb7_harapan}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja7b-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick7b" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbb7_kinerja}}</div>
                                                    </b-button-group>
                                              
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="status.status_sistem_pk === 'Buka'" type="button" @click="updateempati" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan Form</button>
                                <!--end: Datatable-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">C. RELIABILITY/KETERANDALAN</h4>
                    <div class="my-5">
                        <div class="card card-custom">
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%">No</th>
                                            <th style="width: 55%">DIMENSI KINERJA LAYANAN PTSP PEMDA </th>
                                            <th style="width: 40%">PENILAIAN (Masing-masing kolom, diisi dengan rentang nilai 1 sd 10)</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Kompetensi SDM PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan1c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick1c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc1_kepentingan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan1c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick1c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc1_harapan}}</div>
                                                    </b-button-group>
                                                  
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja1c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick1c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc1_kinerja}}</div>
                                                    </b-button-group>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Ketersediaan data dan informasi terkait penanaman modal (tata ruang, peta potensi, dan lain sebagainya)</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan2c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick2c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc2_kepentingan}}</div>
                                                    </b-button-group>
                                                 
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan2c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick2c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc2_harapan}}</div>
                                                    </b-button-group>
                                                   
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja2c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick2c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc2_kinerja}}</div>
                                                    </b-button-group>
                                                
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Akurasi informasi yang disampaikan oleh PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan3c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick3c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc3_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan3c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick3c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc3_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja3c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick3c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc3_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">4</p></td>
                                            <td><p style="font-size: 14px;">Fasilitasi penanaman modal oleh PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan4c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick4c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc4_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan4c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick4c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc4_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja4c-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick4c" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbc4_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="status.status_sistem_pk === 'Buka'" type="button" @click="updatereliability" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan Form</button>
                                <!--end: Datatable-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">D. RESPONSIVENESS/DAYA TANGGAP</h4>
                    <div class="my-5">
                        <div class="card card-custom">
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%">No</th>
                                            <th style="width: 55%">DIMENSI KINERJA LAYANAN PTSP PEMDA </th>
                                            <th style="width: 40%">PENILAIAN (Masing-masing kolom, diisi dengan rentang nilai 1 sd 10)</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Kecepatan pelayanan perizinan berusaha oleh PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan1d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick1d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd1_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan1d-container" class="group-tampilan-button" >
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick1d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd1_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja1d-container"  class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick1d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd1_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Kecepatan pelayanan pengaduan di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan2d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick2d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd2_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan2d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick2d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd2_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja2d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick2d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd2_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Kesigapan penyelesaian masalah oleh PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan3d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick3d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd3_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan3d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick3d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd3_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja3d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick3d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd3_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">4</p></td>
                                            <td><p style="font-size: 14px;">Kesigapan PTSP Pemda untuk menindaklanjuti rekomendasi dan masukan yang diberikan</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan4d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick4d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd4_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan4d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick4d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd4_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja4d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick4d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd4_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">5</p></td>
                                            <td><p style="font-size: 14px;">Kesigapan PTSP Pemda dalam mengawal proyek penanaman modal besar</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan5d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick5d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd5_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan5d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick5d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd5_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja5d-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick5d" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbd5_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="status.status_sistem_pk === 'Buka'" type="button" @click="updateresponsiv" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan Form</button>
                                <!--end: Datatable-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Step 6-->
              <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">E. ASSURANCE/JAMINAN KEPASTIAN</h4>
                    <div class="my-5">
                        <div class="card card-custom">
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%">No</th>
                                            <th style="width: 55%">DIMENSI KINERJA LAYANAN PTSP PEMDA </th>
                                            <th style="width: 40%">PENILAIAN (Masing-masing kolom, diisi dengan rentang nilai 1 sd 10)</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Kejelasan persyaratan dalam pengurusan perizinan berusaha di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan1e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick1e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe1_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan1e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick1e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe1_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja1e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick1e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe1_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Ketepatan waktu dalam pengurusan perizinan berusaha di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan2e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick2e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe2_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan2e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick2e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe2_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja2e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick2e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe2_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Kejelasan prosedur/tata cara dalam mengurus perizinan berusaha di PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan3e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick3e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe3_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan3e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick3e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe3_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja3e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick3e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe3_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">4</p></td>
                                            <td>Kejelasan biaya pelayanan perizinan berusaha di PTSP Pemda</td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan4e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick4e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe4_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan4e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick4e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe4_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja4e-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick4e" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbe4_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="status.status_sistem_pk === 'Buka'" type="button" @click="updateassurance" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan Form</button>
                                <!--end: Datatable-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!--end: Wizard Step 6-->

              <!--begin: Wizard Step 7-->
              <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">F. TOTAL KINERJA</h4>
                    <div class="my-5">
                        <div class="card card-custom">
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%">No</th>
                                            <th style="width: 55%">DIMENSI KINERJA LAYANAN PTSP PEMDA </th>
                                            <th style="width: 40%">PENILAIAN (Masing-masing kolom, diisi dengan rentang nilai 1 sd 10)</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;"> Bagaimana kualitas pelayanan perizinan berusaha yang diberikan oleh PTSP Pemda? </p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan1f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick1f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf1_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan1f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick1f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf1_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja1f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick1f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf1_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Bagaimana kinerja PTSP Pemda dalam mendukung kemudahan berusaha?</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan2f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick2f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf2_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan2f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick2f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf2_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja2f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick2f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf2_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Bagaimana kualitas SDM yang dimiliki PTSP Pemda dalam memberikan pelayanan perizinan berusaha?</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan3f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick3f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf3_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan3f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick3f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf3_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja3f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick3f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf3_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">4</p></td>
                                            <td><p style="font-size: 14px;">Bagaimana kualitas sarana dan prasarana yang digunakan PTSP Pemda?</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Tingkat Kepentingan</p>
                                                    <b-button-group id="tingkat-kepentingan4f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kepentinganClick4f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf4_kepentingan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Harapan</p>
                                                    <b-button-group id="tingkat-harapan4f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_harapanClick4f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf4_harapan}}</div>
                                                    </b-button-group>
                                                </div>
                                                <div class="form-group">
                                                    <p style="font-size: 14px;">Kinerja</p>
                                                    <b-button-group id="tingkat-kinerja4f-container" class="group-tampilan-button">
                                                        <b-btn  v-for="(datakepentingan, k) in kepentingan" :key="k" :value="datakepentingan.value" @click="_kinerjaClick4f" :name="datakepentingan.name">{{ datakepentingan.name }}</b-btn>
                                                        <div style="display:none">{{pm.pbf4_kinerja}}</div>
                                                    </b-button-group>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="status.status_sistem_pk === 'Buka'" type="button" @click="updatetotal" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan Form</button>
                                <!--end: Datatable-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!--end: Wizard Step 7-->

              <!--begin: Wizard Step 8-->
              <div class="pb-5" data-wizard-type="step-content">
                    <h4 class="mb-10 font-weight-bold text-dark">G. PERSEPSI TERHADAP PTSP PEMDA YANG DINILAI DIBANDINGKAN PTSP PEMDA LAINNYA</h4>
                    <div class="my-5">
                        <div class="card card-custom">
                            <div class="card-body">
                                <!--begin: Datatable-->
                                <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th style="width: 5%; display:none">No</th>
                                            <th style="width: 55%; display:none">DIMENSI KINERJA LAYANAN PTSP PEMDA </th>
                                            <th style="width: 40%; display:none">PENILAIAN (Masing-masing kolom, diisi dengan rentang nilai 1 sd 10)</th>
                                            </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colspan="3"><p style="font-size: 14px;">Dibandingkan dengan PTSP Pemda lain yang pernah dikunjungi, bagaimana pendapat Bapak/Ibu terhadap:</p></td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Kualitas pelayanan perizinan berusaha PTSP Pemda yang dinilai</p></td>
                                            <td>
                                                <div>
                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        id="radio-group-1"
                                                        v-model="pm.pbga1"
                                                        :options="persepsi"
                                                        :aria-describedby="ariaDescribedby"
                                                        name="radio-options1"
                                                    ></b-form-radio-group>
                                                    </b-form-group>    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Integritas (bebas KKN) PTSP Pemda yang dinilai</p></td>
                                            <td>
                                                <div>
                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        id="radio-group-2"
                                                        v-model="pm.pbga2"
                                                        :options="persepsi"
                                                        :aria-describedby="ariaDescribedby"
                                                        name="radio-options2"
                                                    ></b-form-radio-group>
                                                    </b-form-group>      
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Kualitas kinerja PTSP Pemda yang dinilai</p></td>
                                            <td>
                                                <div>
                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        id="radio-group-3"
                                                        v-model="pm.pbga3"
                                                        :options="persepsi"
                                                        :aria-describedby="ariaDescribedby"
                                                        name="radio-options3"
                                                    ></b-form-radio-group>
                                                    </b-form-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"><p style="font-size: 14px;">Dibandingkan dengan tahun sebelumnya, bagaimana pendapat Bapak/Ibu terhadap :</p></td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Peran PTSP Pemda dalam peningkatan penanaman modal di daerah</p></td>
                                            <td>
                                                <div>
                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        id="radio-group-4"
                                                        v-model="pm.pbgb1"
                                                        :options="persepsi"
                                                        :aria-describedby="ariaDescribedby"
                                                        name="radio-options4"
                                                    ></b-form-radio-group>
                                                    </b-form-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Kualitas pelayanan perizinan berusaha yang diberikan PTSP Pemda kepada masyarakat/pelaku usaha di daerah</p></td>
                                            <td>
                                                <div>
                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        id="radio-group-5"
                                                        v-model="pm.pbgb2"
                                                        :options="persepsi"
                                                        :aria-describedby="ariaDescribedby"
                                                        name="radio-options5"
                                                    ></b-form-radio-group>
                                                    </b-form-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Kemudahan berusaha yang dirasakan pelaku usaha atas kinerja PTSP Pemda</p></td>
                                            <td>
                                                <div>
                                                    <b-form-group label="" v-slot="{ ariaDescribedby }">
                                                    <b-form-radio-group
                                                        id="radio-group-6"
                                                        v-model="pm.pbgb3"
                                                        :options="persepsi"
                                                        :aria-describedby="ariaDescribedby"
                                                        name="radio-options6"
                                                    ></b-form-radio-group>
                                                    </b-form-group>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3"><p style="font-size: 14px;">Saran dan Masukan (Isian)</p></td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">1</p></td>
                                            <td><p style="font-size: 14px;">Dalam hal pelayanan perizinan berusaha yang diberikan PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <textarea type="text" v-model="pm.pbgc1" class="form-control"/>
                                                </div>
                                            </td>
                                        </tr>
                                            <tr>
                                            <td><p style="font-size: 14px;">2</p></td>
                                            <td><p style="font-size: 14px;">Dalam hal SDM PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <textarea type="text" v-model="pm.pbgc2" class="form-control"/>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p style="font-size: 14px;">3</p></td>
                                            <td><p style="font-size: 14px;">Dalam hal sarana dan prasarana yang digunakan PTSP Pemda</p></td>
                                            <td>
                                                <div class="form-group">
                                                    <textarea type="text" v-model="pm.pbgc3" class="form-control"/>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <button v-if="status.status_sistem_pk === 'Buka'" type="button" @click="updatepersepsi" style="float: right;" class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"><i class="fas fa-save"></i>Simpan Form</button>
                                <!--end: Datatable-->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              <!--end: Wizard Step 8-->


              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-prev"
                  >
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                    data-toggle="modal" data-target="#verifikasiinput"
                  >
                    Selesai
                  </button>
                  <div v-if="idpenilaian != 'undefined' && pm.persetujuan === 'Ya' ">
                    <button id="next"
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                    >
                        Langkah Selanjutnya
                    </button>
                  </div>
                  <div v-else-if="idpenilaian != 'undefined' && pm.persetujuan === 'Tidak' ">

                  </div>
                  <div v-else>
                       <button id="next"
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                    >
                        Langkah Selanjutnya
                    </button>
                  </div>
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>

    <!-- modal verifikasi selesai input -->
    <div class="modal fade" id="verifikasiinput" tabindex="-1" role="dialog" aria-labelledby="verifikasiinput" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title" id="verifikasiinput">Konfirmasi</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <b>Demikian data dan informasi ini disampaikan dan saya bertanggung jawab atas kebenaran data dan informasi tersebut.</b><br>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Batal</button>
            <button type="button" class="btn btn-primary" style="background-color: #030A8C; border-color: #030A8C" data-dismiss="modal" v-on:click="submit">Ya</button>
        </div>
        </div>
    </div>
    </div>

    <div class="modal" ref="modal" id="loginModal" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Insert required data</h5>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group">
                <label for="username">Username</label>
                <input type="text" class="form-control">
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" data-dismiss="modal">Submit</button>
            <a href="#"  data-dismiss="modal">
              <span></span>
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal ref="my-modal" hide-footer title="Using Component Methods">
      <div class="d-block text-center">
        <h3>Hello From My Modal!</h3>
      </div>
      <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button>
      <b-button class="mt-2" variant="outline-warning" block >Toggle Me</b-button>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";
</style>


<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from 'axios';
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  name: "PPK-Asosiasi",
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        user: JSON.parse(localStorage.getItem('user')),
        idasosiasi: localStorage.getItem('idasosiasi'),
        idptsp: localStorage.getItem('idptsp'),
        idpenilaian: localStorage.getItem('idpenilaian'),
        tahun: 2024,
        tanggal: localStorage.getItem('tanggal'),
        pm:[],
        setuju: [
          { text: 'Ya', value: 'Ya' },
          { text: 'Tidak', value: 'Tidak' },
        ],
        persetujuan: '',
        alasan: '',
        kepentingan:[
            {name:1, value:1},
            {name:2, value:2},
            {name:3, value:3},
            {name:4, value:4},
            {name:5, value:5},
            {name:6, value:6},
            {name:7, value:7},
            {name:8, value:8},
            {name:9, value:9},
            {name:10, value:10}
        ],
        persepsi: [
          { text: 'Lebih baik', value: 10 },
          { text: 'Sama saja', value: 0 },
          { text: 'Lebih buruk', value: -10 },
        ],
        selectedkepengurusan: null,
        kepengurusan: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, aktif', text: 'Ada, aktif' },
          { value: 'Ada, kurang aktif', text: 'Ada, kurang aktif' },
          { value: 'Ada, tidak aktif', text: 'Ada, tidak aktif' },
        ],
        selectedproker: null,
        proker: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, berjalan', text: 'Ada, berjalan' },
          { value: 'Ada, kurang berjalan', text: 'Ada, kurang berjalan' },
          { value: 'Ada, tidak berjalan', text: 'Ada, tidak berjalan' },
        ],
        selectedkoordinasi: null,
        koordinasi: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, rutin', text: 'Ada, rutin' },
          { value: 'Ada, tidak rutin', text: 'Ada, tidak rutin' },
          { value: 'Tidak pernah', text: 'Tidak pernah' },
        ],
        selectedkegiatan: null,
        kegiatan: [
          { value: null, text: 'Silahkan Pilih' },
          { value: 'Ada, banyak', text: 'Ada, banyak' },
          { value: 'Ada, sedikit', text: 'Ada, sedikit' },
          { value: 'Tidak ada', text: 'Tidak ada' },
        ],
        selectedkoordinasiterakhir: null,
        koordinasiterakhir: [
          { value: null, text: 'Silahkan Pilih' },
          { value: '1 bulan terakhir', text: '1 bulan terakhir' },
          { value: '6 bulan terakhir', text: '6 bulan terakhir' },
          { value: 'Lebih dari 6 bulan yang lalu', text: 'Lebih dari 6 bulan yang lalu' },
        ],
        status:[],
        // notifikasi:'',
      }
  },
  mounted() {
    this.loaddata();
    // this.showModal();
    // this.notifikasi();
    // this.openModal()
    // this._kepentinganClick();
    // this._kepentinganClick();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Input PPK Organisasi", route: "asosiasi" },
    //   { title: "PTSP" }
    ]);
    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: true // allow step clicking
    });
    // Validation before going to next page
    wizard.on("beforeNext", function(/*wizardObj*/) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });
    
    // Change event
    wizard.on("change", function(/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
    $(document).ready(function(){
        $("#tidaksetuju").click(function () {
            if ($(this).is(":checked")) {
                $("#alasan").show();
                $("#next").hide();
            } else {
                $("#alasan").hide();
                $("#next").show();
            }
        }); 
        $("#setuju").click(function () {
            if ($(this).is(":checked")) {
                $("#alasan").hide();
                $("#next").show();
            } else {
                $("#alasan").show();
                $("#next").hide();
            }
        }); 
    
      });   
  },
  methods: {
      showModal() {
        // if (this.pm.persetujuan === 'Tidak') {
            this.$refs['my-modal'].show()
        // } else if(this.pm.persetujuan === 'Ya') {
        //     this.$refs['my-modal'].show()
        // } else{

        // }
        // this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      loaddata(){
         axios.get(this.url+`/tanggal_kegiatans?filter=tanggal_mulai,<=,`+this.tanggal+`;tanggal_selesai,>=,`+this.tanggal, {
                headers: {
                    'xth': this.token
                }
            }).then(response => {
                this.status = response.data.data;
            }).catch(error=>{
                Swal.fire({
                    title: error.response.data,
                    text: "",
                    icon: 'error',
                    allowOutsideClick: false,
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$store
                            .dispatch(LOGOUT)
                            .then(() => this.$router.push({ name: "login" }));
                        }
                    })
                return error
            });;
    
        axios.get(this.url+`/penilaian_asosiasi?filter=id,=,`+this.idpenilaian+`;tahun,=,`+this.tahun, {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            this.pm = response.data.data;
            this.notif = response.data.data.persetujuan;
            // localStorage.setItem('idpenilaian', JSON.stringify(response.data.data.id))
            // console.log(this.notif)
            // // this.idpenilaian=response.data.data.id;
            // this.notifikasi()
        }).catch(error=>{
            // localStorage.setItem('idpenilaian', 'undefined')
            console.log(error)
            return error
            // this.loaddata()
            });
    },
    getsetuju(){
        axios.get(this.urlpenilaian+`/tanggal_kegiatans?filter=tanggal_mulai,<=,`+this.tanggal+`;tanggal_selesai,>=,`+this.tanggal).then(response => {
            this.status = response.data.data;
        });

        axios.get(this.urlpenilaian+`/penilaian_asosiasi?filter=id,=,`+this.idpenilaian+`;tahun,=,`+this.tahun)
        .then(response => {
            this.pm = response.data.data;
            this.notif = response.data.data.persetujuan;
            localStorage.setItem('idpenilaian', JSON.stringify(response.data.data.id))
            this.notifikasi()
        }).catch(error=>{
            localStorage.setItem('idpenilaian', 'undefined')
            console.log(error)
            return error
            // this.loaddata()
            });
    },
    submitpersetujuan(){
        axios
        .post(
            this.urlpenilaian+"/penilaian_asosiasi",
            {
            // .put('http://penkin-penilaian.mysurvey.id/penilaian_mandiri/kelembagaan/'+ this.idprofil, {
            // _method: 'patch',
            idptsp:this.user.id_ptsp,
            persetujuan:this.persetujuan,
            alasan:this.alasan,
            idasosiasi:this.user.id_ptsp,
          })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            // localStorage.setItem('idpmppb', JSON.stringify(response.data.id))
            this.idpenilaian=response.data.id;
            this.getsetuju()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
    },
    updatetangible(){
        if (this.pm.pba1_kepentingan == 0 || this.pm.pba1_harapan == 0 ||
        this.pm.pba1_kinerja == 0 || this.pm.pba2_kepentingan == 0 || this.pm.pba2_harapan == 0 ||
        this.pm.pba2_kinerja == 0 || this.pm.pba3_kepentingan == 0 || this.pm.pba3_harapan == 0 ||
        this.pm.pba3_kinerja == 0) {

            this.$bvToast.toast('Ada pertanyaan yang belum terjawab', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            
        } else {
            axios
            .put(this.url+'/penilaian_asosiasi/tangible/'+ this.idpenilaian, {
            _method: 'patch',
            pba1_kepentingan:this.pm.pba1_kepentingan,
            pba1_harapan:this.pm.pba1_harapan,
            pba1_kinerja:this.pm.pba1_kinerja,
            pba2_kepentingan:this.pm.pba2_kepentingan,
            pba2_harapan:this.pm.pba2_harapan,
            pba2_kinerja:this.pm.pba2_kinerja,
            pba3_kepentingan:this.pm.pba3_kepentingan,
            pba3_harapan:this.pm.pba3_harapan,
            pba3_kinerja:this.pm.pba3_kinerja,
            // nama_user: this.user.name,
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
        }
        
    },
    updateempati(){
        if (this.pm.pbb1_kepentingan == 0 || this.pm.pbb1_harapan == 0 || this.pm.pbb1_kinerja == 0 ||
        this.pm.pbb2_kepentingan == 0 || this.pm.pbb2_harapan == 0 || this.pm.pbb2_kinerja == 0 || 
        this.pm.pbb3_kepentingan == 0 || this.pm.pbb3_harapan == 0 || this.pm.pbb3_kinerja == 0 || 
        this.pm.pbb4_kepentingan == 0 || this.pm.pbb4_harapan == 0 || this.pm.pbb4_kinerja == 0 ||
        this.pm.pbb5_kepentingan == 0 || this.pm.pbb5_harapan == 0 || this.pm.pbb5_kinerja == 0 || 
        this.pm.pbb6_kepentingan == 0 || this.pm.pbb6_harapan == 0 || this.pm.pbb6_kinerja == 0 ||
        this.pm.pbb7_kepentingan == 0 || this.pm.pbb7_harapan == 0 || this.pm.pbb7_kinerja == 0) {
            this.$bvToast.toast('Ada pertanyaan yang belum terjawab', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
        } else {
            axios
            .put(this.url+'/penilaian_asosiasi/empati/'+ this.idpenilaian, {
            _method: 'patch',
            pbb1_kepentingan:this.pm.pbb1_kepentingan,
            pbb1_harapan:this.pm.pbb1_harapan,
            pbb1_kinerja:this.pm.pbb1_kinerja,
            pbb2_kepentingan:this.pm.pbb2_kepentingan,
            pbb2_harapan:this.pm.pbb2_harapan,
            pbb2_kinerja:this.pm.pbb2_kinerja,
            pbb3_kepentingan:this.pm.pbb3_kepentingan,
            pbb3_harapan:this.pm.pbb3_harapan,
            pbb3_kinerja:this.pm.pbb3_kinerja,
            pbb4_kepentingan:this.pm.pbb4_kepentingan,
            pbb4_harapan:this.pm.pbb4_harapan,
            pbb4_kinerja:this.pm.pbb4_kinerja,
            pbb5_kepentingan:this.pm.pbb5_kepentingan,
            pbb5_harapan:this.pm.pbb5_harapan,
            pbb5_kinerja:this.pm.pbb5_kinerja,
            pbb6_kepentingan:this.pm.pbb6_kepentingan,
            pbb6_harapan:this.pm.pbb6_harapan,
            pbb6_kinerja:this.pm.pbb6_kinerja,
            pbb7_kepentingan:this.pm.pbb7_kepentingan,
            pbb7_harapan:this.pm.pbb7_harapan,
            pbb7_kinerja:this.pm.pbb7_kinerja,
            // nama_user: this.user.name,
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
        }
    },
    updatereliability(){
        if (this.pm.pbc1_kepentingan == 0 || this.pm.pbc1_harapan == 0 || this.pm.pbc1_kinerja == 0 ||
        this.pm.pbc2_kepentingan == 0 || this.pm.pbc2_harapan == 0 || this.pm.pbc2_kinerja == 0 ||
        this.pm.pbc3_kepentingan == 0 || this.pm.pbc3_harapan == 0 || this.pm.pbc3_kinerja == 0 ||
        this.pm.pbc4_kepentingan == 0 || this.pm.pbc4_harapan == 0 || this.pm.pbc4_kinerja == 0) {
            this.$bvToast.toast('Ada pertanyaan yang belum terjawab', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
        } else {
            axios
            .put(this.url+'/penilaian_asosiasi/reliability/'+ this.idpenilaian, {
            _method: 'patch',
            pbc1_kepentingan:this.pm.pbc1_kepentingan,
            pbc1_harapan:this.pm.pbc1_harapan,
            pbc1_kinerja:this.pm.pbc1_kinerja,
            pbc2_kepentingan:this.pm.pbc2_kepentingan,
            pbc2_harapan:this.pm.pbc2_harapan,
            pbc2_kinerja:this.pm.pbc2_kinerja,
            pbc3_kepentingan:this.pm.pbc3_kepentingan,
            pbc3_harapan:this.pm.pbc3_harapan,
            pbc3_kinerja:this.pm.pbc3_kinerja,
            pbc4_kepentingan:this.pm.pbc4_kepentingan,
            pbc4_harapan:this.pm.pbc4_harapan,
            pbc4_kinerja:this.pm.pbc4_kinerja,
            // nama_user: this.user.name,
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
        }
    },
    updateresponsiv(){
        if (this.pm.pbd1_kepentingan == 0 || this.pm.pbd1_harapan == 0 || this.pm.pbd1_kinerja == 0 ||
        this.pm.pbd2_kepentingan == 0 || this.pm.pbd2_harapan == 0 || this.pm.pbd2_kinerja == 0 ||
        this.pm.pbd3_kepentingan == 0 || this.pm.pbd3_harapan == 0 || this.pm.pbd3_kinerja == 0 ||
        this.pm.pbd4_kepentingan == 0 || this.pm.pbd4_harapan == 0 || this.pm.pbd4_kinerja == 0 ||
        this.pm.pbd5_kepentingan == 0 || this.pm.pbd5_harapan == 0 || this.pm.pbd5_kinerja == 0) {
            this.$bvToast.toast('Ada pertanyaan yang belum terjawab', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
        } else {
            axios
            .put(this.url+'/penilaian_asosiasi/responsiveness/'+ this.idpenilaian, {
            _method: 'patch',
            pbd1_kepentingan:this.pm.pbd1_kepentingan,
            pbd1_harapan:this.pm.pbd1_harapan,
            pbd1_kinerja:this.pm.pbd1_kinerja,
            pbd2_kepentingan:this.pm.pbd2_kepentingan,
            pbd2_harapan:this.pm.pbd2_harapan,
            pbd2_kinerja:this.pm.pbd2_kinerja,
            pbd3_kepentingan:this.pm.pbd3_kepentingan,
            pbd3_harapan:this.pm.pbd3_harapan,
            pbd3_kinerja:this.pm.pbd3_kinerja,
            pbd4_kepentingan:this.pm.pbd4_kepentingan,
            pbd4_harapan:this.pm.pbd4_harapan,
            pbd4_kinerja:this.pm.pbd4_kinerja,
            pbd5_kepentingan:this.pm.pbd5_kepentingan,
            pbd5_harapan:this.pm.pbd5_harapan,
            pbd5_kinerja:this.pm.pbd5_kinerja,
            // nama_user: this.user.name,
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
        }
    },
    updateassurance(){
        if (this.pm.pbe1_kepentingan == 0 || this.pm.pbe1_harapan == 0 || this.pm.pbe1_kinerja == 0 ||
        this.pm.pbe2_kepentingan == 0 || this.pm.pbe2_harapan == 0 || this.pm.pbe2_kinerja == 0 ||
        this.pm.pbe3_kepentingan == 0 || this.pm.pbe3_harapan == 0 || this.pm.pbe3_kinerja == 0 || 
        this.pm.pbe4_kepentingan == 0 || this.pm.pbe4_harapan == 0 || this.pm.pbe4_kinerja == 0) {
            this.$bvToast.toast('Ada pertanyaan yang belum terjawab', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
        } else {
            axios
            .put(this.url+'/penilaian_asosiasi/assurance/'+ this.idpenilaian, {
            _method: 'patch',
            pbe1_kepentingan:this.pm.pbe1_kepentingan,
            pbe1_harapan:this.pm.pbe1_harapan,
            pbe1_kinerja:this.pm.pbe1_kinerja,
            pbe2_kepentingan:this.pm.pbe2_kepentingan,
            pbe2_harapan:this.pm.pbe2_harapan,
            pbe2_kinerja:this.pm.pbe2_kinerja,
            pbe3_kepentingan:this.pm.pbe3_kepentingan,
            pbe3_harapan:this.pm.pbe3_harapan,
            pbe3_kinerja:this.pm.pbe3_kinerja,
            pbe4_kepentingan:this.pm.pbe4_kepentingan,
            pbe4_harapan:this.pm.pbe4_harapan,
            pbe4_kinerja:this.pm.pbe4_kinerja,
            // nama_user: this.user.name,
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
        }
    },
    updatetotal(){
        if (this.pm.pbf1_kepentingan == 0 || this.pm.pbf1_harapan == 0 || this.pm.pbf1_kinerja == 0 ||
        this.pm.pbf2_kepentingan == 0 || this.pm.pbf2_harapan == 0 || this.pm.pbf2_kinerja == 0 ||
        this.pm.pbf3_kepentingan == 0 || this.pm.pbf3_harapan == 0 || this.pm.pbf3_kinerja == 0 ||
        this.pm.pbf4_kepentingan == 0 || this.pm.pbf4_harapan == 0 || this.pm.pbf4_kinerja == 0) {
            this.$bvToast.toast('Ada pertanyaan yang belum terjawab', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
        } else {
            axios
            .put(this.url+'/penilaian_asosiasi/totkin/'+ this.idpenilaian, {
            _method: 'patch',
            pbf1_kepentingan:this.pm.pbf1_kepentingan,
            pbf1_harapan:this.pm.pbf1_harapan,
            pbf1_kinerja:this.pm.pbf1_kinerja,
            pbf2_kepentingan:this.pm.pbf2_kepentingan,
            pbf2_harapan:this.pm.pbf2_harapan,
            pbf2_kinerja:this.pm.pbf2_kinerja,
            pbf3_kepentingan:this.pm.pbf3_kepentingan,
            pbf3_harapan:this.pm.pbf3_harapan,
            pbf3_kinerja:this.pm.pbf3_kinerja,
            pbf4_kepentingan:this.pm.pbf4_kepentingan,
            pbf4_harapan:this.pm.pbf4_harapan,
            pbf4_kinerja:this.pm.pbf4_kinerja,
            // nama_user: this.user.name,
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
        }
    },
    updatepersepsi(){
        axios
            .put(this.url+'/penilaian_asosiasi/persepsi/'+ this.idpenilaian, {
            _method: 'patch',
            pbga1:this.pm.pbga1,
            pbga2:this.pm.pbga2,
            pbga3:this.pm.pbga3,
            pbgb1:this.pm.pbgb1,
            pbgb2:this.pm.pbgb2,
            pbgb3:this.pm.pbgb3,
            pbgc1:this.pm.pbgc1,
            pbgc2:this.pm.pbgc2,
            pbgc3:this.pm.pbgc3,
            // nama_user: this.user.name,
          },{
                headers:{
                "xth": this.token
                }
            })
          .then(response => {
            this.$bvToast.toast('Berhasil Disimpan', {
                title: `Success `,
                variant: `success`,
                solid: true
            })
            this.loaddata()
            return response
          })
          .catch(error=>{
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            console.log(error)
            return error
            });
    },
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "Pengisian telah selesai",
        icon: "success",
        confirmButtonClass: "btn btn-secondary"
      });this.$router.push({ name: "dashboard" })
    },
    notifikasi() {
    //   e.preventDefault();
        if (this.notif === 'Ya') {
            Swal.fire({
            title: "",
            text: "Anda sudah menyetujui persetujuan, silahkan klik tombol Langkah Selanjutnya untuk mengisi / melihat",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
            });
        } else if(this.notif === 'Tidak'){
            Swal.fire({
            title: "",
            text: "Anda sudah menolak persetujuan",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
            });this.$router.push({ name: "dashboard" })
        }else{

        }
    },
    _kepentinganClick:function(e){
        // value = this.pm.pba1_kepentingan;
        // console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan1-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba1_kepentingan = value;
    },
    _harapanClick:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan1-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba1_harapan = value;
    },
    _kinerjaClick:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja1-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba1_kinerja = value;
    },
    _kepentinganClick2:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan2-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba2_kepentingan = value;
    },
    _harapanClick2:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan2-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba2_harapan = value;
    },
    _kinerjaClick2:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja2-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba2_kinerja = value;
    },
    _kepentinganClick3:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan3-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba3_kepentingan = value;
    },
    _harapanClick3:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan3-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba3_harapan = value;
    },
    _kinerjaClick3:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja3-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pba3_kinerja = value;
    },

    // empati
    _kepentinganClick1b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan1b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb1_kepentingan = value;
    },
    _harapanClick1b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan1b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb1_harapan = value;
    },
    _kinerjaClick1b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja1b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb1_kinerja = value;
    },
    _kepentinganClick2b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan2b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb2_kepentingan = value;
    },
    _harapanClick2b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan2b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb2_harapan = value;
    },
    _kinerjaClick2b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja2b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb2_kinerja = value;
    },
    _kepentinganClick3b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan3b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb3_kepentingan = value;
    },
    _harapanClick3b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan3b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb3_harapan = value;
    },
    _kinerjaClick3b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja3b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb3_kinerja = value;
    },
    _kepentinganClick4b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan4b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb4_kepentingan = value;
    },
    _harapanClick4b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan4b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb4_harapan = value;
    },
    _kinerjaClick4b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja4b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb4_kinerja = value;
    },
    _kepentinganClick5b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan5b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb5_kepentingan = value;
    },
    _harapanClick5b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan5b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb5_harapan = value;
    },
    _kinerjaClick5b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja5b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb5_kinerja = value;
    },
    _kepentinganClick6b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan6b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb6_kepentingan = value;
    },
    _harapanClick6b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan6b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb6_harapan = value;
    },
    _kinerjaClick6b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja6b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb6_kinerja = value;
    },
    _kepentinganClick7b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan7b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb7_kepentingan = value;
    },
    _harapanClick7b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan7b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb7_harapan = value;
    },
    _kinerjaClick7b:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja7b-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbb7_kinerja = value;
    },

    // reliability
    _kepentinganClick1c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan1c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc1_kepentingan = value;
    },
    _harapanClick1c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan1c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc1_harapan = value;
    },
    _kinerjaClick1c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja1c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc1_kinerja = value;
    },
    _kepentinganClick2c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan2c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc2_kepentingan = value;
    },
    _harapanClick2c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan2c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc2_harapan = value;
    },
    _kinerjaClick2c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja2c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc2_kinerja = value;
    },
    _kepentinganClick3c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan3c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc3_kepentingan = value;
    },
    _harapanClick3c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan3c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc3_harapan = value;
    },
    _kinerjaClick3c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja3c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc3_kinerja = value;
    },
    _kepentinganClick4c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan4c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc4_kepentingan = value;
    },
    _harapanClick4c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan4c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc4_harapan = value;
    },
    _kinerjaClick4c:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja4c-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbc4_kinerja = value;
    },

    // responsive
    _kepentinganClick1d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan1d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd1_kepentingan = value;
    },
    _harapanClick1d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan1d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd1_harapan = value;
    },
    _kinerjaClick1d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja1d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd1_kinerja = value;
    },
    _kepentinganClick2d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan2d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd2_kepentingan = value;
    },
    _harapanClick2d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan2d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd2_harapan = value;
    },
    _kinerjaClick2d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja2d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd2_kinerja = value;
    },
    _kepentinganClick3d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan3d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd3_kepentingan = value;
    },
    _harapanClick3d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan3d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd3_harapan = value;
    },
    _kinerjaClick3d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja3d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd3_kinerja = value;
    },
    _kepentinganClick4d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan4d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd4_kepentingan = value;
    },
    _harapanClick4d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan4d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd4_harapan = value;
    },
    _kinerjaClick4d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja4d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd4_kinerja = value;
    },
    _kepentinganClick5d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan5d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd5_kepentingan = value;
    },
    _harapanClick5d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan5d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd5_harapan = value;
    },
    _kinerjaClick5d:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja5d-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbd5_kinerja = value;
    },

    // assurance
    _kepentinganClick1e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan1e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe1_kepentingan = value;
    },
    _harapanClick1e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan1e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe1_harapan = value;
    },
    _kinerjaClick1e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja1e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe1_kinerja = value;
    },
    _kepentinganClick2e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan2e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe2_kepentingan = value;
    },
    _harapanClick2e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan2e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe2_harapan = value;
    },
    _kinerjaClick2e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja2e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe2_kinerja = value;
    },
    _kepentinganClick3e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan3e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe3_kepentingan = value;
    },
    _harapanClick3e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan3e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe3_harapan = value;
    },
    _kinerjaClick3e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja3e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe3_kinerja = value;
    },
    _kepentinganClick4e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan4e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe4_kepentingan = value;
    },
    _harapanClick4e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan4e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe4_harapan = value;
    },
    _kinerjaClick4e:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja4e-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbe4_kinerja = value;
    },

    // total
    _kepentinganClick1f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan1f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf1_kepentingan = value;
    },
    _harapanClick1f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan1f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf1_harapan = value;
    },
    _kinerjaClick1f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja1f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf1_kinerja = value;
    },
    _kepentinganClick2f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan2f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf2_kepentingan = value;
    },
    _harapanClick2f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan2f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf2_harapan = value;
    },
    _kinerjaClick2f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja2f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf2_kinerja = value;
    },
    _kepentinganClick3f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan3f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf3_kepentingan = value;
    },
    _harapanClick3f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan3f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf3_harapan = value;
    },
    _kinerjaClick3f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja3f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf3_kinerja = value;
    },
    _kepentinganClick4f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kepentingan4f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf4_kepentingan = value;
    },
    _harapanClick4f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-harapan4f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf4_harapan = value;
    },
    _kinerjaClick4f:function(e){
        console.log("koko ni iru");
        console.log();
        let container = document.querySelector('#tingkat-kinerja4f-container');
        let buttons = container.childNodes;
        
        console.log(e);
        let elementClicked = e.srcElement;
        console.log(elementClicked);
        let value = elementClicked.getAttribute("value");
        for(let i=0;i<buttons.length;i++){
            let buttonValue = buttons[i].getAttribute("value");
            let style = "color:black; display:none";
            if(value == buttonValue){
                style = "color:white; background-color:red";
                buttons[i].style = style;
            } else {
                style = "color:black; display:none";
            }
        }
        // v-model="pm.pba1_kepentingan"
        this.pm.pbf4_kinerja = value;
    },
    openModal () {
      $('#loginModal').modal('show')
    },
  },
  updated:function(){
      let groupsTampilanButton = document.getElementsByClassName("group-tampilan-button");
      console.log("koko ni iru");
      console.log(groupsTampilanButton);
      for(let i=0;i<groupsTampilanButton.length;i++){
          let component = groupsTampilanButton[i];
          let value = component.querySelector('div').innerHTML;
          let childs = component.childNodes;
          for(let i=0;i<childs.length;i++){
              if(childs[i].nodeName == "DIV"){
                  continue;
              }
              let buttonValue = childs[i].getAttribute("value");
              let style = "color:black";
              if(value == buttonValue){
                style = "color:white; background-color:red";
              }
              childs[i].style = style;
          }
          
      }
  },
  computed:{
      getstatus(){
        var batasakhir = new Date('2021-05-30');
            var date = batasakhir.getFullYear()+'-'+(batasakhir.getMonth()+1)+'-'+batasakhir.getDate();

        if (this.tanggal > date) {
            return 'tutup'
            console.log('tutup woi')
            console.log(date)
        } else {
            return 'buka'
            console.log('buka woi')
            console.log(date)
        }
    }
  }
};
</script>